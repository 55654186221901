@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* clears the 'X' from Internet Explorer */
  input.hide-clear[type=search]::-ms-clear,
  input.hide-clear[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Webkit */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Thin.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Thin.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/Lato/Lato-ThinItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-ThinItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Light.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Light.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/Lato/Lato-LightItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-LightItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Light.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Light.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Lato/Lato-LightItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-LightItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Medium.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Lato/Lato-MediumItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-MediumItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Medium.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Lato/Lato-MediumItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-MediumItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Bold.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Lato/Lato-BoldItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-BoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Bold.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Lato/Lato-BoldItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-BoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Black.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Black.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/Lato/Lato-BlackItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-BlackItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Lato';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/Lato/Lato-Black.woff2") format("woff2"),
         url("../fonts/Lato/Lato-Black.woff") format("woff");
  }
  @font-face {
    font-family: 'Lato';
    font-style:  italic;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/Lato/Lato-BlackItalic.woff2") format("woff2"),
         url("../fonts/Lato/Lato-BlackItalic.woff") format("woff");
  }
}

@layer components {
  .e-mail:before {
    content: attr(data-website) "\0040" attr(data-user);
    unicode-bidi: bidi-override;
    direction: rtl;
  }
}

@layer utilities {

}
